import axios from "./axios"
export class CustomerService {
    //Admin 
    getAllCustomers(){
        return axios.get("/customers")

    }

    getAllCustomersPostPaid(){
        return axios.get("/customers/post-paid")

    }
}
export default new CustomerService();