import axios from "./axios"
export class BillService {
  //Admin 
  getAllBills() {
    return axios.get("/bills")

  }

  //Admin 
  getAllBillsPending() {
    return axios.get("/bills/pending")

  }

  //Admin 
  getAllBillsPaid() {
    return axios.get("/bills/paid")

  }

  //Admin 
  getAllBillsByCustomer() {
    return axios.get("/bills")

  }

  getCustomerBundleById(id) {
    return axios.get("/bills/" + id)

  }
}
export default new BillService();