import React, { Component,Fragment } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import {
  Container, Card, CardHeader, CardBody, Button, Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter } from "reactstrap";
import Moment from 'moment'
import ReactDatatable from '@ashvin27/react-datatable';
import { AuthService,TariffService } from '../../../../services';
import { Redirect } from 'react-router-dom';
import axios from "../../../../services/axios";

class AllTariffs extends Component {
  state = {
    tarriffList: [],
    loading: true,
    modal: false,
    mode: true,
    editedTariff: {
      id: 0,
      tariffName: "",
    },
    tariff: {
      tariffName: "",
    }
  };

  initialState = {
    tariff: {
      tariffName: "",
    }
  }

  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" })
    }
    this.getAllTariffs();
  }

  getAllTariffs=()=>{

    TariffService.getAllTariffs().then(res => {
      this.setState({ loading: false })
      this.setState({ tarriffList: res.data })
    })
  }

  formatDate = (date) => {
    return Moment(date).format('lll')
  }

  ViewTarriffBandDetails = (row) => {
    return this.props.history.push('/admin-tariff-bands/' + row.id, row)
  }


  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  }

  config = {
    page_size: 10,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {
      // excel: true,
      // print: true,
      // csv: true
    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  }

  ucFirst = (str) => {
    if (!str) return str;
    if (str.trim() == "undefined") return "";
    return str[0].toUpperCase() + str.slice(1);
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  AddTariffMode = () => {
    this.setState({ mode: true })
    this.toggleModal();
  }

  handleChange = event => {
    if (this.state.mode) {
      this.setState({
        tariff: Object.assign({},
          this.state.tariff, { [event.target.name]: event.target.value })
      })
    } else {
      this.setState({
        editedTariff: Object.assign({}, this.state.editedTariff,
          { [event.target.name]: event.target.value })
      })
    }
  }


  handleSubmit = event => {
    event.preventDefault();
    this.toggleModal();
    if (this.state.mode) {
      console.log("Add mode")
      const data = this.state.tariff;
      console.log("tariff", this.state.tariff)

      axios.post("/tariff", this.state.tariff).then(res => {
        console.log(res.data);
        this.getAllTariffs();
        this.setState({ tariff: this.initialState.tariff })
      })

    } else {
      console.log("Edit mode")
      axios.put("/tariff", this.state.editedTariff).then(res => {
        console.log(res.data);
        this.getAllTariffs();

      })
    }
  }

  EditTariff(row) {
    console.log(row)
    const editedTariff = {
      id: row.id,
      tariffName: row.tariffName,
    }
    this.setState({ editedTariff })
    this.setState({ mode: false })
    this.toggleModal();
  }

  DeleteTariff(id) {
    axios.delete("/tariff/" + id)
      .then(res => {
        const response = res.data;
        const tarriffList = this.state.tarriffList.filter((item) => {
          return item.id !== id;
        });
        this.setState({ tarriffList })
      })
  }


  columns = [
    {
      key: "id",
      text: "#",
      sortable: true,
      cell: (record, index) => {
        return index + 1;
      }
    },
    {
      key: "tariffName",
      text: "TARIFF NAME",
    },
    {
      key: "id",
      text: "ACTION",
      cell: (record, index) => {
        return (
          <Fragment>
            <span className="btn badge-success mr-2 px-4" onClick={() => this.EditTariff(record)}> <i className="icon-pencil mr-2"  ></i>Edit</span>
            <span className="btn bg-danger-dark  px-4 mr-2" onClick={() => this.DeleteTariff(record.id)}> <i className="fa fa-trash mr-2"></i>Delete</span>
          <span className="btn btn-warning px-4" onClick={() => this.ViewTarriffBandDetails(record)}> <i className="fa fa-info mr-2"></i>View Tariff Bands</span>
        </Fragment>
        );
      }
    }

  ];


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="mr-auto flex-row">
            Tariffs List
            <small>Showing all tariffs.</small>
          </div>
          <div className="flex-row">
            <Button onClick={this.AddTariffMode} style={this.AddActionButtonStyle} className="btn-pill-right">
              <i className="fa fa-plus mr-2"></i>
              Add New Tariff</Button>
          </div>


          <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>{this.state.mode ? "Add New Tariff" : "Edit Tariff"}</ModalHeader>
            <form onSubmit={this.handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <label>Tariff Name :</label>
                  <input className="form-control" name="tariffName"
                    value={this.state.mode ? this.state.tariff.tariffName : this.state.editedTariff.tariffName}
                    onChange={this.handleChange} type="text" required></input>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-sm btn-success mr-3  px-5" type="submit">
                  Save
                </button>
              </ModalFooter>
            </form>
          </Modal>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
            </CardHeader>
            <CardBody>

              <ReactDatatable
                extraButtons={this.extraButtons}
                config={this.config}
                records={this.state.tarriffList}
                columns={this.columns}
                loading={this.state.loading}

              />
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default AllTariffs;
