import React, { Component ,Fragment} from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";

import { Container, Card, CardHeader, CardBody, CardTitle, Button } from "reactstrap";
import Moment from 'moment'
import ReactDatatable from '@ashvin27/react-datatable';
import { AuthService, SmsTemplateService } from '../../../../services';
import { Redirect } from 'react-router-dom';

class AllSmsTemplates extends Component {
  state = {
    smsTemplateList: [],
    loading: true
  };

  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" })
    }

    this.getAllSmsTemplates();
  }

  getAllSmsTemplates=()=>{
    SmsTemplateService.getAllSmsTemplates().then(res => {
      this.setState({ loading: false })
      this.setState({ smsTemplateList: res.data })
    })
  }

  formatDate = (date) => {
    return Moment(date).format('lll')
  }

  ViewCustomerDetails = (row) => {
    return this.props.history.push('/admin-farmer-details/' + row.id, row)
  }

  AddFarmer=()=>{
    return this.props.history.push("/admin-add-farmer");
  }


  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  }

  disableSmsTemplate(id) {
    SmsTemplateService.disableSmsTemplates(id).then(res=>{
     this.getAllSmsTemplates();
    })
  }

  config = {
    page_size: 10,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {
      // excel: true,
      // print: true,
      // csv: true
    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  }

  ucFirst = (str) => {
    if (!str) return str;
    if (str.trim() == "undefined") return "";
    return str[0].toUpperCase() + str.slice(1);
  }



  columns = [
    {
      key: "id",
      text: "#",
      sortable: true,
      cell: (record, index) => {
        return index + 1;
      }
    },
    {
      key: "customerName",
      text: "CUSTOMER",
      cell: (record, index) => {
        return this.ucFirst(record.customerName)
      }
    },
    {
      key: "messageTemplate",
      text: "MESSAGE",
    },
    {
      key: "status",
      text: "STATUS",
       cell: (record, index) => {
        if (record.status == 1) {
          return (<span className="badge badge-success">Approved</span>)
        }
        if (record.status == 3) {
          return (<span className="badge badge-danger">Disabled</span>)
        }
        
      }
    },
    {
      key: "createdAt",
      text: "DATE REGISTERED",
      sortable: true,
      cell: (record, index) => {
        return (this.formatDate(record.createdAt))
      }
    },
    {
      key: "id",
      text: "ACTION",
      cell: (record, index) => {
        if (record.status == 1) {
          return (
            <Fragment>
              <span className="btn bg-danger-dark  px-4" onClick={() => this.disableSmsTemplate(record.id)}> <i className="fa fa-power-off mr-2"></i>Disable</span>
            </Fragment>
          );
        }
      }
    }

  ];


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="mr-auto flex-row">
            Sms Template List
            <small>Showing all sms templates.</small>
          </div>
          <div className="flex-row">
            {/* <Button onClick={this.AddFarmer} style={this.AddActionButtonStyle} className="btn-pill-right">
              <i className="fa fa-eye mr-2"></i>
               Requested Sms Templates</Button> */}
          </div>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
            </CardHeader>
            <CardBody>

              <ReactDatatable
                extraButtons={this.extraButtons}
                config={this.config}
                records={this.state.smsTemplateList}
                columns={this.columns}
                loading={this.state.loading}

              />
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default AllSmsTemplates;
