import axios from "./axios"
export class TariffService {

    //Admin 
    getAllTariffs(){
        return axios.get("/tariff")

    }

    

}
export default new TariffService();