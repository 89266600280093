import axios from "./axios"
export class CustomerBundle {
  //Admin 
  getAllCustomerBundles() {
    return axios.get("/customer-bundles")

  }

  getCustomerBundleById(id) {
    return axios.get("/customer-bundles/customer/"+id)

  }
}
export default new CustomerBundle();