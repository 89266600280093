import React, { Component, Fragment } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import {
  Container, Card, CardHeader, CardBody, Button, Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter
} from "reactstrap";
import Moment from 'moment'
import ReactDatatable from '@ashvin27/react-datatable';
import { AuthService, TariffService } from '../../../../services';
import { Redirect } from 'react-router-dom';
import axios from "../../../../services/axios";

class AllTariffBands extends Component {
  state = {
    tarriffList: [],
    tariffBandList:[],
    loading: true,
    modal: false,
    mode: true,
    tarriffId:0,
    tariffName:"",
    editedTariffBand: {
      id: 0,
      fromAmount: 0,
      toAmount: 0,
      pricePerSms: 0,
      expireDurationDays: -1,
      tariffId: 0
    },
    tariffBand: {
      fromAmount: 0,
      toAmount: 0,
      pricePerSms: 0,
      expireDurationDays: -1,
      tariffId: 0
    }
  };

  initialState = {
    tariffBand: {
      fromAmount: 0,
      toAmount: 0,
      pricePerSms: 0,
      expireDurationDays: -1,
      tariffId: 0
    }
  }

  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" })
    }


    const { state } = this.props.history.location;
    if (state == undefined) {
      return this.props.history.push('/admin-tariffs')
    }

    this.setState({ tarriffId: state.id })
    this.setState({ tariffName: state.tariffName})
    this.getAllTariffBands(state.id)
  }

  getAllTariffBands = (id) => {
    axios.get("/tariff-bands/tariff/" +id)
      .then(res => {
        this.setState({ loading: false })
        console.log(res.data);
        this.setState({ tariffBandList: res.data })
      }
      )
  }

  formatDate = (date) => {
    return Moment(date).format('lll')
  }

  
  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  }

  config = {
    page_size: 10,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {
      // excel: true,
      // print: true,
      // csv: true
    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  }

  ucFirst = (str) => {
    if (!str) return str;
    if (str.trim() == "undefined") return "";
    return str[0].toUpperCase() + str.slice(1);
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  AddTariffMode = () => {
    this.setState({ mode: true })
    this.toggleModal();
  }

  ViewAllTariffs = () => {
    return this.props.history.push("/admin-tariffs");
  }

  handleChange = event => {
    if (this.state.mode) {
      this.setState({
        tariffBand: Object.assign({},
          this.state.tariffBand, { [event.target.name]: event.target.value })
      })
    } else {
      this.setState({
        editedTariffBand: Object.assign({}, this.state.editedTariffBand,
          { [event.target.name]: event.target.value })
      })
    }
  }


  handleSubmit = event => {
    event.preventDefault();
    this.toggleModal();
    if (this.state.mode) {
      console.log("Add mode")
      const tarriffId=this.state.tarriffId;
      
      let data = this.state.tariffBand;
      let finalData = { ...data, tariffId:tarriffId}
      console.log("tariff Bands", this.state.tariffBand)

      axios.post("/tariff-bands", finalData).then(res => {
        console.log(res.data);
        this.getAllTariffBands(this.state.tarriffId);
        this.setState({ tariffBand: this.initialState.tariffBand })
      })

    } else {
      console.log("Edit mode")
      axios.put("/tariff-bands", this.state.editedTariffBand).then(res => {
        console.log(res.data);
        this.getAllTariffBands(this.state.tarriffId);

      })
    }
  }

  EditTariff(row) {
    console.log(row)
    const tarriffId = this.state.tarriffId;
    const editedTariffBand = {
      id: row.id,
      fromAmount: row.fromAmount,
      toAmount: row.toAmount,
      pricePerSms: row.pricePerSms,
      expireDurationDays: row.expireDurationDays,
      tariffId: tarriffId
    }
    this.setState({ editedTariffBand })
    this.setState({ mode: false })
    this.toggleModal();
  }

  DeleteTariffBand(id) {
    axios.delete("/tariff-bands/" + id)
      .then(res => {
        const response = res.data;
        const tariffBandList = this.state.tariffBandList.filter((item) => {
          return item.id !== id;
        });
        this.setState({ tariffBandList })
      })
  }


  columns = [
    {
      key: "id",
      text: "#",
      sortable: true,
      cell: (record, index) => {
        return index + 1;
      }
    },
    {
      key: "tariffName",
      text: "TARIFF NAME",
      cell:(record,index)=>{
        return this.state.tariffName;
      }
    },
    {
      key: "fromAmount",
      text: "FROM AMOUNT",
      cell: (record, index) => {
        return record.fromAmount?record.fromAmount:0;
      }
    },
    {
      key: "toAmount",
      text: "TO AMOUNT",
    },
    {
      key: "pricePerSms",
      text: "PRICE PER SMS",
    },
    {
      key: "expireDurationDays",
      text: "EXPIRATION (DAYS)",
    },
    {
      key: "id",
      text: "ACTION",
      cell: (record, index) => {
        return (
          <Fragment>
            <span className="btn badge-success mr-2 px-4" onClick={() => this.EditTariff(record)}> <i className="icon-pencil mr-2"  ></i>Edit</span>
            <span className="btn bg-danger-dark  px-4 mr-2" onClick={() => this.DeleteTariffBand(record.id)}> <i className="fa fa-trash mr-2"></i>Delete</span>
          </Fragment>
        );
      }
    }

  ];


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="mr-auto flex-row">
            Tariff Bands : {this.state.tariffName}
            <small>Showing all tariffs bands.</small>
          </div>
          <div className="flex-row">
            <Button onClick={this.AddTariffMode} style={this.AddActionButtonStyle} className="btn-pill-right">
              <i className="fa fa-plus mr-2"></i>
              Add New Tariff Band</Button>

            <Button onClick={this.ViewAllTariffs} style={this.AddActionButtonStyle} className="btn-pill-right">
              <i className="fa fa-eye mr-2"></i>
              View Tariffs </Button>
          </div>


          <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>{this.state.mode ? "Add New Tariff Band " : "Edit Tariff Band"}</ModalHeader>
            <form onSubmit={this.handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <label>From Amount :</label>
                  <input className="form-control" name="fromAmount"
                    value={this.state.mode ? this.state.tariffBand.fromAmount : this.state.editedTariffBand.fromAmount}
                    onChange={this.handleChange} type="number" required></input>
                </FormGroup>
                <FormGroup>
                  <label>To Amount :</label>
                  <input className="form-control" name="toAmount"
                    value={this.state.mode ? this.state.tariffBand.toAmount : this.state.editedTariffBand.toAmount}
                    onChange={this.handleChange} type="number" required></input>
                </FormGroup>
                <FormGroup>
                  <label>Price Per SMS :</label>
                  <input className="form-control" name="pricePerSms"
                    value={this.state.mode ? this.state.tariffBand.pricePerSms : this.state.editedTariffBand.pricePerSms}
                    onChange={this.handleChange} type="number" required></input>
                </FormGroup>
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Expiration(Days) : </label>
                  <select className="form-control" id="exampleFormControlSelect1" name="expireDurationDays"
                    onChange={this.handleChange}
                    value={this.state.mode ? this.state.tariffBand.expireDurationDays : this.state.editedTariffBand.expireDurationDays}
                  >
                    <option value="-1">Select number days</option>
                    <option value="30">30 Days</option>
                    <option value="60">60 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                    <option value="">Never</option>
                  </select>
                </div>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-sm btn-success mr-3  px-5" type="submit">
                  Save
                </button>
              </ModalFooter>
            </form>
          </Modal>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
            </CardHeader>
            <CardBody>

              <ReactDatatable
                extraButtons={this.extraButtons}
                config={this.config}
                records={this.state.tariffBandList}
                columns={this.columns}
                loading={this.state.loading}

              />
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default AllTariffBands;
