import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import ContentWrapper from "../../../Layout/ContentWrapper";
import axios from "../../../../services/axios";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap';
import EasyPieChart from 'easy-pie-chart';

import CardTool from '../../../Common/CardTool'
import Sparkline from '../../../Common/Sparklines';
import FlotChart from '../../../Common/Flot';
import Now from '../../../Common/Now';
import { AuthService, DashboardService } from '../../../../services';
import {Redirect} from 'react-router-dom'

import { ChartSpline } from "../../Admin/Dashboard/DashboardChart";
class UserDashboard extends Component {

    state = {
        spineChartdata: [{
            "label": "Total Sms Sent",
            "color": "#768294",
            "data": []
        }],
        dashboardData: {
            totalSmsSent:0,
            smsBalance:0,
            totalSmsFailed:0,
            currentSmsBalance:0,
            totalSmsDelivered:0
        },
        isPrePaid: true,
        purchasedSms: ""

    }

    componentDidMount() {
        // window.location.reload(false);

        const isAuthenticated = AuthService.isAuthenticated();
        if (!isAuthenticated) {
          this.setState({ redirect: "/login" })
        }

        axios.get("/dashboard")
            .then(res => {
                const response = res.data;
                this.setState({ dashboardData: response })
                this.customerSmsPurchase();
                console.log(response);
                this.getDashboardChartData();
            })
        
    }

    customerSmsPurchase = () => {
        const paymentType=this.state.dashboardData.paymentType;
        // const paymentType = "Post-Paid"
        if (paymentType == "Post-Paid") {
            this.setState({ purchasedSms: "Post-Paid" })
            this.setState({ isPrePaid: false })
        } else {
            this.setState({ purchasedSms: this.state.dashboardData.smsBalance })
        }
    }

    getDashboardChartData = () => {
        DashboardService.getDashboardChartData().then(res => {

            const result = res.data;
            const finalResult = [];

            result.forEach(item => {
                const itemArray = [];
                itemArray.push(item.day)
                itemArray.push(item.total);
                finalResult.push(itemArray);

            });

            const spineChartdata = [{
                "label": "Total Sms Sent",
                "color": "#37bc9b",
                "data": finalResult
            }]
            this.setState({ spineChartdata })
        })
    }

    AddActionButtonStyle = {
        color: 'white',
        background: "#003366"
    }

    logout=()=>{
        SenderIdService.logout().then((res) => {
            this.setState({redirect:"/login"})
        }, (err) => {
            this.setState({redirect:"/login"})
        })
    }

    render() {
        // Usse t function instead of Trans component
        // const { t } = this.props;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                   

                    <div className="mr-auto flex-row">
                    Dashboard
                    <small>Welcome to WEB-SMS</small>
          </div>
          <div className="flex-row">
          <Button  style={this.AddActionButtonStyle} className="btn-pill-right">
          {this.state.dashboardData.paymentType} Customer
            </Button>
          
          </div>
                </div>
                { /* START cards box */}
                <Row>
                <Col xl={3} lg={6} md={12}>
                            { /* START card */}
                            <div className="card flex-row align-items-center align-items-stretch border-0">
                                <div
                                    className="col-4 d-flex align-items-center bg-green justify-content-center rounded-left">
                                    <em className="icon-bubbles fa-3x"></em>
                                </div>
                                <div className="col-8 py-3 bg-green rounded-right">
                                    <div className="h2 mt-0">{this.state.dashboardData.currentSmsBalance}</div>
                                    <div className="text-uppercase">Sms Balance</div>
                                </div>
                            </div>
                        </Col>
                    <Col xl={3} md={6}>
                        { /* START card */}
                        <div className="card flex-row align-items-center text-white align-items-stretch border-0">
                            <div
                                className="col-4 d-flex align-items-center bg-dark justify-content-center rounded-left">
                                <em className="icon-layers fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 bg-dark rounded-right">
                                <div className="h2 mt-0">{this.state.dashboardData.totalSmsSent}</div>
                                <div className="text-uppercase">Message Sent</div>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} md={6}>
                        { /* START card */}
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div
                                className="col-4 d-flex align-items-center bg-info justify-content-center rounded-left">
                                <em className="icon-bubble fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 bg-info rounded-right">
                                <div className="h2 mt-0">{this.state.dashboardData.totalSmsDelivered}</div>
                                <div className="text-uppercase">Message Delivered</div>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={12}>
                        { /* START card */}
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div
                                className="col-4 d-flex align-items-center bg-danger justify-content-center rounded-left">
                                <em className="icon-bubble fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 bg-danger rounded-right">
                                <div className="h2 mt-0">{this.state.dashboardData.totalSmsFailed}</div>
                                <div className="text-uppercase">Message Failed</div>
                            </div>
                        </div>
                    </Col>
                    
                        
                    
                </Row>
                { /* END cards box */}
                <Row>
                    { /* START dashboard main content */}
                    <Col xl={12}>
                        { /* START chart */}
                        <Row>
                            <Col xl={12}>
                                { /* START card */}
                                <div className="card card-default">
                                    <div className="card-header">
                                        <CardTool refresh onRefresh={(_, done) => setTimeout(this.getDashboardChartData(), 2000)} />
                                        <div className="card-title"> Messages statistics</div>
                                    </div>
                                    <div className="card-body">
                                        <FlotChart options={ChartSpline.options} data={this.state.spineChartdata} className="flot-chart" height="400px" />
                                    </div>
                                </div>
                                { /* END widget */}
                            </Col>
                        </Row>
                        { /* END chart */}
                    </Col>
                   
                </Row>
            </ContentWrapper>
        );
    }
}

export default withTranslation('translations')(UserDashboard);
