const Menu = [
    {
        heading: 'Main Navigation',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Dashboard',
        path: '/admin-dashboard',
        icon: 'icon-speedometer',
        translate: 'sidebar.nav.DASHBOARD'
    },
    {
        name: 'Customers',
        icon: 'icon-list',
        path: '/admin-customers'
    },
    {
        name: 'Sender ID\'s',
        icon: 'icon-bubbles',
        path: '/admin-senders'

    },
    {
        name: 'Sms Templates',
        icon: 'icon-layers',
        path: '/admin-sms-templates'

    },
    {
        name: 'Tariffs',
        icon: 'icon-directions',
        path: '/admin-tariffs'

    },
    {
        name: 'Transactions',
        icon: 'icon-briefcase',
        path: '/admin-transactions'

    },
    {
        name: 'Manage Users',
        icon: 'fa fa-users-cog',
        path: '/admin-manage-users'
    },
    {
        name: 'Account Management',
        icon: 'icon-note',
        path: '/admin-self-manage'
    },
    {
        name: 'System Setup',
        icon: 'fas fa-wrench',
        path: '/admin-manage-setup',
        translate: 'sidebar.nav.SETUP',
        submenu: [
            {
                name: 'Mobile Operators',
                icon: "icon-arrow-right",
                translate: 'sidebar.nav.setup.INPUTS',
                path: '/admin-mobile-operators'
            },
            {
                name: 'SMSC Accounts',
                icon: "icon-arrow-right",
                translate: 'sidebar.nav.setup.INPUTS',
                path: '/admin-manage-smsc'
            },
            {
                name: 'Alert Emails',
                icon: "icon-arrow-right",
                translate: 'MCU',
                path: '/admin-alert-emails'
            },
            {
                name: 'SMS Logs',
                icon: "icon-arrow-right",
                translate: 'AMCOS',
                path: '/admin-sms-log',
            }

        ]
    },
    /**User pages on Refactor DELETE the routes */
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'icon-speedometer',
        translate: 'sidebar.nav.DASHBOARD'
    },
    {
        name: 'Sender ID\'s',
        icon: 'icon-bubbles',
        path: '/senders'
    },
    {
        name: 'SMS Templates',
        icon: 'icon-layers',
        path: '/sms-templates'
    },
    {
        name: 'Send SMS',
        icon: 'fa fa-paper-plane',
        path: '/sms-send'
        // path: '/send-sms'
    },
    {
        name: 'Scheduled SMS',
        icon: 'fa fa-clock',
        path: '/sms-scheduled'
    },
    {
        name: 'SMS Outbox',
        icon: 'icon-list',
        path: '/sms-outbox'
    },
    {
        name: 'Purchase SMS',
        icon: 'icon-briefcase',
        path: '/sms-purchase'
    },
    // {
    //     name: 'Invoices',
    //     icon: 'fa fa-file',
    //     // path: '/prepaid-invoices'
    //     path: '/senders'
    // },
    {
        name: 'Contact lists',
        icon: 'fa fa-address-book',
        path: '/contact-lists'
    },
    {
        name: 'Manage Users',
        icon: 'fa fa-users',
        path: '/manage-users'
    },
    {
        name: 'Manage User',
        icon: 'fa fa-users',
        path: '/manage-user'
    },
    {
        name: 'SMS Reports',
        icon: 'fa fa-file-pdf',
        path: '/manage-reports',
        submenu: [{
            name: 'Summary Reports',
            icon: 'fa fa-file-pdf',
            path: '/summary-reports'
        },
        {
            name: 'Sender Reports',
            icon: 'fa fa-file-pdf',
            path: '/sender-reports'
        },

        ]
    },


];


export default Menu;
