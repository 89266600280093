import axios from "./axios"
export class SmsTemplateService {

    //Admin 
    getAllSmsTemplates(){
        return axios.get("/sms-templates")

    }

    disableSmsTemplates(id){
        return axios.put("/sms-templates/disable/"+id)
    }

    

}
export default new SmsTemplateService();