export const ChartSpline = {
  options: {
    series: {
      lines: {
        show: false
      },
      points: {
        show: true,
        radius: 5
      },
      splines: {
        show: true,
        tension: 0.2,
        lineWidth: 1,
        fill: 0.5
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 2,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => x + ' : ' + y
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'categories'
    },
    yaxis: {
       // optional: use it for a clear represetation
      tickColor: '#eee',
      //position: 'right' or 'left',
      tickFormatter: v => v /* + ' visitors'*/
    },
    shadowSize: 0
  }

}