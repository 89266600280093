import React, { Component } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import axios from "../../../../services/axios";
import Datatable from "../../../Common/Datatable";
import { Container, Card, CardHeader, CardBody, CardTitle, Button } from "reactstrap";
import $ from "jquery";
import Moment from "moment"
import NumberFormat from 'react-number-format'
import { AuthService } from '../../../../services';
import ReactDatatable from '@ashvin27/react-datatable';
class SmsSummaryReports extends Component {


  state = {
    billsList: []
  };

  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" });
    }
    this.getAllComplaints();


  }

  getAllComplaints = () => {
    axios.get("/bills/customer")
      .then(res => {
        const response = res.data;
        this.setState({ billsList: response })

      })
  };

  columns = [
    {
      key: "id",
      text: "#",
      sortable: true,
      cell: (record, index) => {
        return index += 1;
      }
    },
    {
      key: "billReference",
      text: "SENDER"
    },
    {
      key: "billNumber",
      text: "SENT SMS"
    },
    {
      key: "paymentMsisdn",
      text: "DELIVERY SMS",
      sortable: true
    },
    {
      key: "billNumber",
      text: "FAILED SMS"
    },
  ];

  config = {
    page_size: 10,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {

    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  }



  formatDate = (date) => {
    return Moment(date).format('lll')
  }

  ViewPrePaidInvoice(row) {
    console.log(row)
    return this.props.history.push('/invoice/' + row.id, row)
  }

  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Sms Summary Reports
            <small>Generate sms reports.</small>
          </div>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
              <div className="row">
                <Card className="col-sm-12">
                  <CardBody>
                    <form onSubmit={this.onSubmit}>
                      <div className="form-row align-items-center">
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>From :</label>
                            <input className="form-control" type="date" />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>To: </label>
                            <input className="form-control" type="date" />
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-info mt-2">
                            Filter
                          </button>
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </div>
            </CardHeader>
            <CardBody>

              <ReactDatatable
                extraButtons={this.extraButtons}
                config={this.config}
                records={this.state.billsList}
                columns={this.columns}
              />
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default SmsSummaryReports;


{/* <table className="table table-striped my-4 w-100">
  <thead>
    <tr>
      <th data-priority="1">#</th>
      <th>SENDER</th>
      <th>SENT SMS</th>
      <th>DELIVERY SMS</th>
      <th>FAILED SMS</th>
      <th>ACTION</th>
    </tr>
  </thead>
  <tbody>
    <tr className="gradeA">
      <td>1</td>
      <td>VODACOM</td>
      <td>15</td>
      <td>12</td>
      <td>3</td>
      <td></td>

    </tr>
  </tbody>
</table> */}