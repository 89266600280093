import React, { Component,Fragment } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";

import {
  Container, Card, CardHeader, CardBody, CardTitle, Button, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Moment from 'moment'
import ReactDatatable from '@ashvin27/react-datatable';
import { AuthService, SenderIdService } from '../../../../services';
import { Redirect } from 'react-router-dom';
import axios from "../../../../services/axios";
import Swal from "sweetalert2"
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class AllSenders extends Component {
  state = {
    sendersList: [],
    loading: true,
    modal: false,
    senderId:""
  };

  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" })
    }
    this.GetAllSenders();

  }

  GetAllSenders=()=>{
    SenderIdService.GetAllSenderIds().then(res => {
      this.setState({ loading: false })
      this.setState({ sendersList: res.data })
    })
  }

  formatDate = (date) => {
    return Moment(date).format('lll')
  }

  ViewCustomerDetails = (row) => {
    return this.props.history.push('/admin-farmer-details/' + row.id, row)
  }

  AddFarmer=()=>{
    return this.props.history.push("/admin-add-farmer");
  }

  
  ViewRequestedSenders = () => {
    return this.props.history.push("/admin-senders-requested");
  }

  disableSenderId = (id) => {
    SenderIdService.DisableSenderId(id).then(
      res => {
        this.GetAllSenders();
      }
    )
  }
  
  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  }

  config = {
    page_size: 10,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {
      // excel: true,
      // print: true,
      // csv: true
    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  }

  ucFirst = (str) => {
    if (!str) return str;
    if (str.trim() == "undefined") return "";
    return str[0].toUpperCase() + str.slice(1);
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  showSweetAlert() {
    return MySwal.fire({
      position: 'center',
      icon: 'success',
      title: 'Added Public SenderId Sucessfully',
      text: "",
      showConfirmButton: false,
      timer: 2000
    })
  }

  showFailedSweetAlert() {
    return MySwal.fire({
      position: 'center',
      icon: 'info',
      title: 'Sender ID is already taken',
      text: "",
      showConfirmButton: false,
      timer: 2000
    })
  }

  handleSubmit = event => {

    event.preventDefault();
    const sender = {
      "senderId": this.state.senderId,

    }
    axios.post("/sender-ids/public", sender).then(res => {
      this.toggleModal();
      console.log(res);
      console.log(res.data);
      this.showSweetAlert()
      this.GetAllSenders();
    }, err => {
      this.toggleModal();
      this.showFailedSweetAlert()
    })
  }


  columns = [
    {
      key: "id",
      text: "#",
      sortable: true,
      cell: (record, index) => {
        return index + 1;
      }
    },
    {
      key: "customerName",
      text: "CUSTOMER",
    },
    {
      key: "senderId",
      text: "SENDER ID"
    },
    {
      key: "isApproved",
      text: "STATUS",
      cell: (record, index) => {
        if (record.isApproved == 1) {
          return (<span className="badge badge-success">Approved</span>)
        }
        if (record.isApproved == 2) {
          return (<span className="badge badge-danger">Rejected</span>)
        }
        if (record.isApproved == 3) {
          return (<span className="badge badge-danger">Disabled</span>)
        }
        if (record.isApproved == 0) {
          return (<span className="badge badge-warning">Pending</span>)
        }
      }
    },
    {
      key: "createdAt",
      text: "DATE REGISTERED",
      sortable: true,
      cell: (record, index) => {
        return (this.formatDate(record.createdAt))
      }
    },
    {
      key: "id",
      text: "ACTION",
      cell: (record, index) => {

         if (record.isActive == 1) {
            return (
          <Fragment>
          <span className="btn bg-danger-dark  px-4" onClick={() => this.disableSenderId(record.id)}> <i className="fa fa-power-off mr-2"></i>Disable</span>
        </Fragment>
        );
        }
       
      }
    }

  ];


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="mr-auto flex-row">
            Senders List
            <small>Showing all senders.</small>
          </div>
          <div className="flex-row">
            <Button onClick={this.ViewRequestedSenders} style={this.AddActionButtonStyle} className="btn-pill-right mr-2">
              <i className="fa fa-eye mr-2"></i>
              View Requested Sender ID's </Button>

            <Button onClick={this.toggleModal} style={this.AddActionButtonStyle} className="btn-pill-right">
              <i className="fa fa-plus mr-2"></i>
               Add Public Sender ID</Button>

            <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
              <ModalHeader toggle={this.toggleModal}>Add Public Sender ID</ModalHeader>
              <form onSubmit={this.handleSubmit}>
                <ModalBody>

                  <div className="form-group px-md-2 px-1">
                    <label>Sender Id :</label>
                    <input className="form-control" name="senderId" onChange={this.handleChange}
                      required ></input>
                  </div>

                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-sm  mr-3 px-4" style={this.AddActionButtonStyle}>
                    Save
                  </button>
                </ModalFooter>
              </form>
            </Modal>
          </div>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
            </CardHeader>
            <CardBody>

              <ReactDatatable
                extraButtons={this.extraButtons}
                config={this.config}
                records={this.state.sendersList}
                columns={this.columns}
                loading={this.state.loading}

              />
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default AllSenders;
