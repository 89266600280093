import axios from "./axios"
export class UsersService {

  //Admin 
  getAllUsers() {
    return axios.get("/users")

  }

  getAllUsersByCustomer(id) {
    return axios.get("/users/byCustomerList/"+id)

  }



}
export default new UsersService();