import React, { Component, Fragment } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";

import { Container, Card, CardHeader, CardBody, CardTitle, Button } from "reactstrap";
import Moment from 'moment'
import ReactDatatable from '@ashvin27/react-datatable';
import { AuthService, SenderIdService } from '../../../../services';
import { Redirect } from 'react-router-dom';

class RequestedSenders extends Component {
  state = {
    sendersList: [],
    loading: true
  };

  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" })
    }
    this.getPendingSenders();
    
  }

  getPendingSenders(){
    SenderIdService.GetAllPendingSenderIds().then(res => {
      this.setState({ loading: false })
      this.setState({ sendersList: res.data })
    })
  }

  formatDate = (date) => {
    return Moment(date).format('lll')
  }

  ViewCustomerDetails = (row) => {
    return this.props.history.push('/admin-farmer-details/' + row.id, row)
  }

   ViewAllSenders = () => {
     return this.props.history.push("/admin-senders");
  }

  approveSenderId=(id)=>{
    SenderIdService.ApproveSenderId(id).then(
      res =>{
        this.getPendingSenders();
      }
    )
  }

  rejectSenderId = (id) => {
    SenderIdService.RejectSenderId(id).then(
      res => {
        this.getPendingSenders();
      }
    )
  }

  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  }

  config = {
    page_size: 10,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {
      // excel: true,
      // print: true,
      // csv: true
    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  }

  ucFirst = (str) => {
    if (!str) return str;
    if (str.trim() == "undefined") return "";
    return str[0].toUpperCase() + str.slice(1);
  }



  columns = [
    {
      key: "id",
      text: "#",
      sortable: true,
      cell: (record, index) => {
        return index + 1;
      }
    },
    {
      key: "customerName",
      text: "CUSTOMER",
    },
    {
      key: "senderId",
      text: "SENDER ID"
    },
    {
      key: "isActive",
      text: "STATUS",
      cell: (record, index) => {
        if (record.isActive == 1) {
          return (<span className="badge badge-success">Approved</span>)
        }
        if (record.isActive == 2) {
          return (<span className="badge badge-danger">Rejected</span>)
        }
        if (record.isActive == 0) {
          return (<span className="badge badge-warning">Pending</span>)
        }
      }
    },
    {
      key: "createdAt",
      text: "DATE REGISTERED",
      sortable: true,
      cell: (record, index) => {
        return (this.formatDate(record.createdAt))
      }
    },
    {
      key: "id",
      text: "ACTION",
      cell: (record, index) => {

        if (record.isActive == 0) {
          return (
            <Fragment>
              <span className="btn bg-success  px-4 mr-2" onClick={() => this.approveSenderId(record.id)}> <i className="fa fa-thumbs-up mr-2"></i>Approve</span>
              <span className="btn bg-danger-dark  px-4" onClick={() => this.rejectSenderId(record.id)}> <i className="fa fa-thumbs-down mr-2"></i>Reject</span>
            </Fragment>
          );
        }

      }
    }

  ];


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="mr-auto flex-row">
            Requested Sender ID's
            <small>Showing all requested senders.</small>
          </div>
          <div className="flex-row">
            <Button onClick={this.ViewAllSenders} style={this.AddActionButtonStyle} className="btn-pill-right mr-2">
              <i className="fa fa-eye mr-2"></i>
              View All Sender ID's </Button>
          </div>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
            </CardHeader>
            <CardBody>

              <ReactDatatable
                extraButtons={this.extraButtons}
                config={this.config}
                records={this.state.sendersList}
                columns={this.columns}
                loading={this.state.loading}

              />
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default RequestedSenders;
