import React, { Component } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import Moment from "moment";
import axios from "../../../../services/axios";
import {
    Container, Button, TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Col,
    Table,
    Card, CardHeader, CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup
} from "reactstrap";
import classnames from 'classnames';
import { calculateAge } from '../../../../utils/AgeCalculator';
import NumberFormat from 'react-number-format'
import ReactDatatable from '@ashvin27/react-datatable';
import { CustomerBundle, UserService } from '../../../../services';


class CustomerDetails extends Component {
    state = {
        activeTab: '1',
        farmerId: 0,
        loading: true,
        customerLoading:true,
        userLoading:true,
        users:[],
        customerBundles:[],
        tarrifsList:[],
        smscList:[],

       
        rejectModal:false,
        

        customer: {
            id: 0,
            fullName: "",
            email: "",
            phoneNumber: "",
            location: "",
            isActive: 0,
            isDeleted: 0,
            customerType: "",
            totalSmsSent: 0,
            totalSmsDelivered: 0,
            totalSmsFailed: 0,
            totalSmsPurchased: 0,
            tariffFk: 1,
            paymentType: "Post-Paid",
            isApproved: 0,
            idNumber: "",
            idType: "",
            createdAt: "",
            autoRenewal: 1

        },
        showSmscDetails: false,
        smscType: -1,

        smscId: 0,
        monthlySmsLimit:0,
        tariffId:0,

        rejectionReason:"",
    };

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    ViewCustomersList = () => {
        return this.props.history.push("/admin-customers")
    }
    componentDidMount() {

        const { state } = this.props.history.location;
        if (state == undefined) {
            return this.props.history.push('/admin-customers')
        }

        this.setState({ farmerId: state.id })
        axios.get("/customers/" + state.id)
            .then(res => {

                this.setState({ customer: { ...this.state.customer, id: res.data.id } })
                this.setState({ customer: { ...this.state.customer, fullName: res.data.fullName } })
                this.setState({ customer: { ...this.state.customer, email: res.data.email } })
                this.setState({ customer: { ...this.state.customer, phoneNumber: res.data.phoneNumber } })
                this.setState({ customer: { ...this.state.customer, location: res.data.location } })
                this.setState({ customer: { ...this.state.customer, isActive: res.data.isActive } })
                this.setState({ customer: { ...this.state.customer, isDeleted: res.data.isDeleted } })
                this.setState({ customer: { ...this.state.customer, customerType: res.data.customerType } })
                this.setState({ customer: { ...this.state.customer, totalSmsSent: res.data.totalSmsSent } })
                this.setState({ customer: { ...this.state.customer, totalSmsDelivered: res.data.totalSmsDelivered } })
                this.setState({ customer: { ...this.state.customer, totalSmsFailed: res.data.totalSmsFailed } })
                this.setState({ customer: { ...this.state.customer, totalSmsPurchased: res.data.totalSmsPurchased } })
                this.setState({ customer: { ...this.state.customer, tariffFk: res.data.tariffFk } })
                this.setState({ customer: { ...this.state.customer, paymentType: res.data.paymentType } })
                this.setState({ customer: { ...this.state.customer, isApproved: res.data.isApproved } })

                this.setState({ customer: { ...this.state.customer, idNumber: res.data.idNumber } })
                this.setState({ customer: { ...this.state.customer, idType: res.data.idType } })
                this.setState({ customer: { ...this.state.customer, createdAt: res.data.createdAt } })
                this.setState({ customer: { ...this.state.customer, autoRenewal: res.data.autoRenewal } })
                
            })
        
        CustomerBundle.getCustomerBundleById(state.id)
            .then(res => {
                this.setState({ customerLoading:false})
                this.setState({ customerBundles:res.data})})
            

        UserService.getAllUsersByCustomer(state.id).then(res=>{
            this.setState({ userLoading:false})
            this.setState({users:res.data})
        })

        axios.get("/tariff")
            .then(res => {
                this.setState({ tarrifsList: res.data })
            })

        axios.get("/smsc/dedicated-pending")
            .then(res => {
                this.setState({ smscList: res.data })
            })

    }

    AddActionButtonStyle = {
        color: 'white',
        background: "#003366"
    }

    TableActionButtonStyle = {
        color: 'white',
        background: "#33414e"
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleRejectModal=()=>{
        this.setState({rejectModal:!this.state.rejectModal})
    }


    formatDate = (date) => {
        return Moment(date).format('lll')
    }

    ucFirst = (str) => {
        if (!str) return str;
        if (str.trim() == "undefined") return "";
        return str[0].toUpperCase() + str.slice(1);
    }

    handleSmsTypeChange = event => {
        if ([event.target.value] == "0") {
            this.setState({ showSmscDetails: false })
            this.setState({ smscId: 0 })
            this.setState({ smscType: 0 })
        } else {
            this.setState({ showSmscDetails: true })
            this.setState({ smscType: 1 })

        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }



    handleSubmit = event => {
        event.preventDefault();
        this.toggleRejectModal();
        const data =
        {
            "customerId": this.state.customer.id,
            "tariffId": this.state.tariffId,
            "smscId": this.state.smscId,
            "monthlySmsLimit": this.state.monthlySmsLimit
        }

        console.log(data)

        axios.put("/customers/approve", data).then(res => {
            const response = res.data;
            this.ViewCustomersList();
        })
    }


    handleRejectFormSubmit = event => {
        event.preventDefault();
        this.toggleModal();
        const data =
        {
            "customerId": this.state.customer.id,
            "message": this.state.rejectionReason,
        }

        console.log(data)

        axios.put("/customers/reject", data).then(res => {
            const response = res.data;
            this.ViewCustomersList();
        })
    }



    getFarmerFullName = () => {
        const fullName = this.ucFirst(this.state.customer.fullName);
        return fullName;
    }

    getStatus=()=>{
        if(this.state.customer.isActive==1){
            return (<span className="badge badge-success">Active</span>)
        }else if(this.state.customer.isActive==0){
            return (<span className="badge badge-warning">Pending</span>)
        }
        else{
            return (<span className="badge badge-danger">Rejected</span>)
        }
    }

    config = {
        page_size: 10,
        length_menu: [10, 25, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        filename: "Contact List",
        button: {

        },
        language: {
            loading_text: "Please be patient while data loads..."
        }
    }


    customerBundleColumns = [
        {
            key: "id",
            text: "#",
            sortable: true,
            cell: (record, index) => {
                return index + 1;
            }
        },
        {
            key: "smsBalance",
            text: "SMS BALANCE"
        },
        {
            key: "totalSms",
            text: "INITIAL SMS"
        },
        {
            key: "status",
            text: "STATUS",
            cell: (record, index) => {
                if (record.status == "Active") {
                    return (<span className="badge badge-success">Active</span>)
                }
                else {
                    return (<span className="badge badge-danger">{this.record.status}</span>)
                } 
            }
        },
        {
            key: "expiryDate",
            text: "EXPIRY DATE",
            sortable: true,
            cell: (record, index) => {
                if (record.expiryDate ==null){
                    return ("Never")  
                }else{
                return (this.formatDate(record.expiryDate))
                }
            }
        }

    ];


    usersColumns = [
        {
            key: "id",
            text: "#",
            sortable: true,
            cell: (record, index) => {
                return index + 1;
            }
        },
        {
            key: "fullName",
            text: "FULL NAME"
        },
        {
            key: "username",
            text: "USERNAME"
        },
        {
            key: "userMonthlySmsLimit",
            text: "MONTHLY SMS LIMIT",
            record: (record, index) => {
                return (<NumberFormat value={record.userMonthlySmsLimit} displayType={'text'} thousandSeparator={true} prefix={''} />)
            }
        },
        {
            key: "isActive",
            text: "STATUS",
            cell: (record, index) => {
                return (this.getStatus(record.isActive))
            }
        },
        {
            key: "registrationDate",
            text: "CREATED AT",
            sortable: true,
            cell: (record, index) => {
                return (this.formatDate(record.registrationDate))
            }
        }

    ];


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div className="mr-auto flex-row">
                        Customer Details : {this.getFarmerFullName()}
                        <small>Showing all customer details.</small>
                    </div>
                    <div className="flex-row d-block d-md-flex">
                        {/* <span className="btn badge-success mr-2 px-4" onClick={() => this.EditFarmer(this.state.farmerId)}> <i className="icon-pencil mr-2"  ></i>Approve Customer</span>
                         <span className="btn bg-danger-dark mr-2 px-4" onClick={() => this.EditFarmer(this.state.farmerId)}> <i className="icon-pencil mr-2"  ></i>Reject Customer</span> */}


                        {/* <span className="btn badge-success mr-2 px-4" onClick={() => this.EditFarmer(this.state.farmerId)}> <i className="icon-pencil mr-2"  ></i>Edit Customer</span> */}
                        {/* <span className="btn bg-danger-dark mr-2 px-4" onClick={() => this.DeleteRole(record.id)}> <i className="fa fa-trash mr-2"></i>Delete Customer </span> */}
                        {this.state.customer.isActive !=1 &&
                          <div>
                                <span className="btn badge-success mr-2 px-4" onClick={() => this.toggleModal()}> <i className="fa fa-thumbs-up  mr-2"  ></i>Approve</span>
                                <span className="btn bg-danger-dark mr-2 px-4" onClick={() => this.toggleRejectModal()}> <i className="fa fa-thumbs-down  mr-2"></i>Reject </span>
                          </div>
                        }

                        <Button onClick={this.ViewFarmersList} style={this.AddActionButtonStyle} className="btn-pill-right">View All Farmers</Button>
                    </div>
                </div>
                <Container fluid>

                    <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>Approve Customer: </ModalHeader>
                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>


                                {this.state.customer.paymentType == "Post-Paid" &&
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Smsc Type : </label>
                                        <select className="form-control" id="exampleFormControlSelect1" name="smscType"
                                            onChange={this.handleSmsTypeChange}
                                            value={this.state.smscType}
                                        >
                                            <option value="-1">Select type</option>
                                            <option value="0">Shared SMSC</option>
                                            <option value="1">Dedicate SMSC</option>
                                        </select>
                                    </div>

                                }

                                {this.state.showSmscDetails &&
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Smsc Account : </label>
                                        <select className="form-control" id="exampleFormControlSelect1" name="smscId"
                                            onChange={this.handleChange}
                                            value={this.state.smscId}
                                        >
                                            <option value="-1">Select account</option>
                                            {this.state.smscList.map(row => (
                                                <option key={row.id} value={row.id} >
                                                    {row.smscUsername}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                }
                                {this.state.customer.paymentType == "Post-Paid" &&
                                    <FormGroup>
                                        <label>Monthly Sms Limit :</label>
                                        <input className="form-control" name="monthlySmsLimit" onChange={this.handleChange} type="number" required></input>
                                    </FormGroup>
                                }
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Tariff : </label>
                                    <select className="form-control" id="exampleFormControlSelect1" name="tariffId" onChange={this.handleChange}>
                                        <option value="0">Select a tariff</option>
                                        {this.state.tarrifsList.map(row => (
                                            <option key={row.id} value={row.id} >
                                                {row.tariffName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-sm btn-success mr-3  px-5" type="submit">
                                    Approve
                                </button>
                            </ModalFooter>
                        </form>
                    </Modal>


                    <Modal isOpen={this.state.rejectModal} toggle={this.toggleRejectModal}>
                        <ModalHeader toggle={this.toggleRejectModal}>Reject Customer : </ModalHeader>
                        <form onSubmit={this.handleRejectFormSubmit}>
                            <ModalBody>
                                <FormGroup>
                                    <label>Reason For Rejection :</label>
                                    <textarea rows="6" className="form-control" name="rejectionReason" type="text" onChange={this.handleChange} value={this.state.rejectionReason}></textarea>
                                </FormGroup>
                         
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-sm btn-success mr-3  px-5" type="submit">
                                    Reject
                                </button>
                            </ModalFooter>
                        </form>
                    </Modal>

                    {/* Tab panels  */}
                    <div role="tabpanel card card-body">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }}>
                                    <span className="icon-people mr-2"></span>
                                    Customer Details
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}>
                                    <span className="fa fa-link mr-2"></span>
                                    Attachments
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggleTab('3'); }}>
                                    <span className="fa fa-cubes mr-2"></span>
                                    Customer Bundles
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '4' })}
                                    onClick={() => { this.toggleTab('4'); }}>
                                    <span className="fa fa-users mr-2"></span>
                                    User Accounts
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">

                                <Col xl="12">
                                    <div>
                                        <div className="card card-default">

                                            <hr className="my-0" />
                                            <div className="card-body mt-2 mb-2">
                                                <div className="">
                                                    <div className="px-2">
                                                        <Table className="table table-bordered">

                                                            <tbody>
                                                                <tr>
                                                                    <th colSpan={4} className="text-uppercase">  <span className="fa fa-user mr-2"></span> Personal Information</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Full Name</th>
                                                                    {/* <td>{this.state.firstName + " " + this.state.surname}</td> */}
                                                                    <td>{this.getFarmerFullName()}</td>
                                                                </tr>

                                                                <tr>

                                                                    <th>Email:</th>
                                                                    <td>{this.state.customer.email}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th>Phone Number:</th>
                                                                    <td>{this.state.customer.phoneNumber}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Customer Type:</th>
                                                                    <td>{this.state.customer.customerType}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Status:</th>
                                                                    <td>{this.getStatus()}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Date Registered:</th>
                                                                    <td>{this.formatDate(this.state.customer.createdAt)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th colSpan={4} className="text-uppercase"><span className="fa fa-address-book mr-2"></span>Identification Information</th>

                                                                </tr>

                                                                <tr>
                                                                    <th>ID Number:</th>
                                                                    <td>{this.state.customer.idNumber}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>ID Type:</th>
                                                                    <td>{this.state.customer.idType}</td>
                                                                </tr>
                                                               

                                                                <tr>
                                                                    <th colSpan={4} className="text-uppercase"><span className="icon-briefcase mr-2"></span>Payment</th>

                                                                </tr>
                                                                <tr>
                                                                    <th>Payment Type:</th>
                                                                    <td>{this.state.customer.paymentType}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th colSpan={4} className="text-uppercase"><span className="fa fa-map-pin mr-2"></span> Address Information</th>

                                                                </tr>
                                                                <tr>
                                                                    <th>Address:</th>
                                                                    <td>{this.state.customer.location}</td>
                                                                </tr>


                                                                <tr>
                                                                    <th colSpan={4} className="text-uppercase"><span className="fa fa-map-pin mr-2"></span> Customer Sms Statistics</th>

                                                                </tr>
                                                                <tr>
                                                                    <th>Sms Sent:</th>
                                                                    <td><NumberFormat value={this.state.customer.totalSmsSent} displayType={'text'} thousandSeparator={true} prefix={''} /></td>
                                                                </tr>

                                                                <tr>
                                                                    <th>Sms Delivered:</th>
                                                                    <td><NumberFormat value={this.state.customer.totalSmsDelivered} displayType={'text'} thousandSeparator={true} prefix={''} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Sms Failed:</th>
                                                                    <td><NumberFormat value={this.state.customer.totalSmsFailed} displayType={'text'} thousandSeparator={true} prefix={''} /></td>
                                                                   
                                                                </tr>
                                                                <tr>
                                                                    <th>Sms Purchased:</th>
                                                                    <td><NumberFormat value={this.state.customer.totalSmsPurchased} displayType={'text'} thousandSeparator={true} prefix={''} /></td>
                                                                    
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>


                            </TabPane>
                            <TabPane tabId="2">

                                <Col xl="12">
                                    <div>
                                        <div className="card">
                                            <hr className="my-0" />
                                            <div className="card-body mt-2 py-1">
                                                <div className="px-md-3 px-2 text-center">
                                                    <Button onClick={this.ViewFarmersList} color="success" size="lg" className="btn ">Preview Document</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </TabPane>
                            <TabPane tabId="3">
                                <Col xl="12">
                                    <Card>
                                        <CardHeader>
                                        </CardHeader>
                                        <CardBody>

                                            <ReactDatatable
                                                extraButtons={this.extraButtons}
                                                config={this.config}
                                                records={this.state.customerBundles}
                                                columns={this.customerBundleColumns}
                                                loading={this.state.customerLoading}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </TabPane>

                            <TabPane tabId="4">

                                <Col xl="12">
                                    <Card>
                                        <CardHeader>
                                        </CardHeader>
                                        <CardBody>
                                            <ReactDatatable
                                                extraButtons={this.extraButtons}
                                                config={this.config}
                                                records={this.state.users}
                                                columns={this.usersColumns}
                                                loading={this.state.userLoading}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </TabPane>


                        </TabContent>
                    </div>
                </Container>
            </ContentWrapper>
        );
    }
}

export default CustomerDetails;
