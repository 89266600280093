import axios from "./axios"
export class DashboardService {

    //Admin 
    getAdminDashboard(){
        return axios.get("/dashboard/admin")

    }

    //Dashobard chart
    getDashboardAdminChartData(){
        return axios.get("/dashboard/admin/chart")
    }

   getDashboardChartData(){
       return axios.get("/dashboard/chart")
   }

}
export default new DashboardService();