import React, { Component } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";

import { Container, Card, CardHeader, CardBody, CardTitle, Button } from "reactstrap";
import Moment from 'moment'
import ReactDatatable from '@ashvin27/react-datatable';
import { AuthService, BillService } from '../../../../services';
import { Redirect } from 'react-router-dom';

class AllPendingTransactions extends Component {
  state = {
    billsList: [],
    loading: true
  };

  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" })
    }

    BillService.getAllBillsPending().then(res => {
      this.setState({ loading: false })
      this.setState({ billsList: res.data })
    })
  }

  formatDate = (date) => {
    return Moment(date).format('lll')
  }

  ViewAdminInvoice = (row) => {
    return this.props.history.push('/admin-invoice/' + row.id, row)
  }

  ViewPaidTransactions = () => {
    return this.props.history.push("/admin-transactions");
  }


  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  }

  config = {
    page_size: 10,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {
      // excel: true,
      // print: true,
      // csv: true
    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  }

  ucFirst = (str) => {
    if (!str) return str;
    if (str.trim() == "undefined") return "";
    return str[0].toUpperCase() + str.slice(1);
  }



  columns = [
    {
      key: "id",
      text: "#",
      sortable: true,
      cell: (record, index) => {
        return index + 1;
      }
    },
    {
      key: "billReference",
      text: "INVOICE"
    },
    {
      key: "billNumber",
      text: "BILL NUMBER"
    },
    {
      key: "customerName",
      text: "CUSTOMER NAME"
    },
    {
      key: "paymentMsisdn",
      text: "MSISDN",
    },
    {
      key: "smsQuantity",
      text: "SMS QUANTITY",
    },
    {
      key: "billAmount",
      text: "AMOUNT",
    },
    {
      key: "status",
      text: "STATUS",
      cell: (record, index) => {
        if (record.status != "Pending") {
          return (<span className="badge badge-success">Paid</span>)
        }
        if (record.status == "Pending") {
          return (<span className="badge badge-warning">Not Paid</span>)
        }
      }
    },
    {
      key: "createdAt",
      text: "CREATED AT",
      sortable: true,
      cell: (record, index) => {
        return (this.formatDate(record.createdAt))
      }
    },
    {
      key: "id",
      text: "ACTION",
      cell: (record, index) => {
        return (
          <Button style={{
            color: 'white',
            background: "#003366"
          }} className="btn btn-success"
            onClick={() => {
              this.ViewAdminInvoice(record);
            }}
          >
            <i className="fa fa-eye"></i>
          </Button>
        );
      }
    }

  ];


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="mr-auto flex-row">
            Pending Payment Transactions
            <small>Showing all pending payment transactions.</small>
          </div>
          <div className="flex-row">
            <Button onClick={this.ViewPaidTransactions} style={this.AddActionButtonStyle} className="btn-pill-right mr-2">
              <i className="fa fa-eye mr-2"></i>
              View Paid Transactions </Button>
          </div>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
            </CardHeader>
            <CardBody>

              <ReactDatatable
                extraButtons={this.extraButtons}
                config={this.config}
                records={this.state.billsList}
                columns={this.columns}
                loading={this.state.loading}

              />
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default AllPendingTransactions;
